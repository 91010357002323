import { Layout } from "antd";
import React, { lazy, useEffect, Suspense } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import { loadCartFromStorage } from "./redux/rtk/features/cart/cartSlice";

import UserPrivateRoute from "./components/PrivacyComponent/UserPrivateRoute";
import AddSale from "./components/sale/addSale";
import DraftContainer from "./components/saleDraft/SaleDraft";

const DetailsSup = lazy(() => import('./components/suppliers/detailsSup'));
const Suppliers = lazy(() => import('./components/suppliers/suppliers'));
const UpdateSup = lazy(() => import('./components/suppliers/updateSup'));

const DetailsProd = lazy(() => import('./components/product/detailsProd'));
const Product = lazy(() => import('./components/product/product'));
const UpdateProd = lazy(() => import('./components/product/updateProd'));
const UpdateSale = lazy(() => import('./components/sale/UpdateSale'));
const DetailsPurch = lazy(() => import('./components/purchase/detailsPurch'));

const LoginSSO = lazy(() => import('./components/user/LoginSSO'));
const SuperAdminLogin = lazy(() => import('./components/user/SuperAdminLogin'));
const Login = lazy(() => import('./components/user/Login'));
const Logout = lazy(() => import('./components/user/Logout'));
const UserList = lazy(() => import('./components/user/user'));
const VendorList = lazy(() => import('./components/vendor/vendor'));

const Customer = lazy(() => import('./components/customer/customer'));
const DetailCust = lazy(() => import('./components/customer/detailCust'));
const UpdateCust = lazy(() => import('./components/customer/updateCust'));

const DetailSale = lazy(() => import('./components/sale/detailSale'));

const Page404 = lazy(() => import('./components/404/404Page'));
const Dashboard = lazy(() => import('./components/Dashboard/Graph/Dashboard'));
const AddCustPaymentByInvoice = lazy(() => import('./components/Payment/CustomerPaymentByInvoice'));
const AddSupPaymentByInvoice = lazy(() => import('./components/Payment/SupplierPaymentByInvoice'));
const UpdateProductBrand = lazy(() => import('./components/productBrand/updateProductBrand'));
const UpdateProductSubcategory = lazy(() => import('./components/ProductSubcategory/updateProductSubcategory'));
const GetAllPurch = lazy(() => import('./components/purchase/getAllPurch'));
const GetAllSale = lazy(() => import('./components/sale/getAllSale'));
const DetailStaff = lazy(() => import('./components/user/detailsStaff'));
const UpdateStaff = lazy(() => import('./components/user/updateStaff'));
const DetailVendor = lazy(() => import('./components/vendor/detailsVendor'));
const UpdateVendor = lazy(() => import('./components/vendor/updateVendor'));

// import Register from "./components/user/Register";
const Account = lazy(() => import('./components/account/account'));
const BalanceSheet = lazy(() => import('./components/account/balanceSheet'));
const DetailAccount = lazy(() => import('./components/account/detailAccount'));
const IncomeStatement = lazy(() => import('./components/account/incomeStatement'));
const TrialBalance = lazy(() => import('./components/account/trialBalance'));
const Designation = lazy(() => import('./components/designation/designation'));
const DetailDesignation = lazy(() => import('./components/designation/detailDesignation'));
const UpdateDesignation = lazy(() => import('./components/designation/updateDesignation'));
const Main = lazy(() => import('./components/layouts/Main'));
const Pos = lazy(() => import('./components/pos/pos'));
const AddProd = lazy(() => import('./components/product/addProd'));
const ImportFromCSV = lazy(() => import('./components/product/UploadMany'));
const DetailProductBrand = lazy(() => import('./components/productBrand/detailProductBrand'));
const ProductBrand = lazy(() => import('./components/productBrand/productBrand'));
const DetailProductCategory = lazy(() => import('./components/productCategory/detailProductCategory'));
const ProductCategory = lazy(() => import('./components/productCategory/productCategory'));
const UpdateProductCategory = lazy(() => import('./components/productCategory/updateProductCategory'));
const DetailProductSubCategory = lazy(() => import('./components/ProductSubcategory/detailProductSubcategory'));
const ProductSubcategory = lazy(() => import('./components/ProductSubcategory/productSubcategory'));
const AddReturnPurchase = lazy(() => import('./components/purchase/addReturnPurchase'));
const AddPermission = lazy(() => import('./components/role/AddPermission'));
const DetailRole = lazy(() => import('./components/role/DetailsRole'));
const RoleList = lazy(() => import('./components/role/role'));
const AddReturnSale = lazy(() => import('./components/sale/addReturnSale'));
const InvoiceSetting = lazy(() => import('./components/settings/invoiceSetting'));
const TemplateSettings = lazy(() => import('./components/settings/TemplateSettings'));
// import TestComp from "./components/Test/TestComp";

const CustomerSettings = lazy(() => import('./components/CustomerSettings/CustomerSettings'));
const DetailProductColor = lazy(() => import('./components/productColor/detailProductColor'));
const ProductColor = lazy(() => import('./components/productColor/productColor'));
const UpdateProductColor = lazy(() => import('./components/productColor/updateProductColor'));
const UpdatePurchase = lazy(() => import('./components/purchase/updatePurchase'));
const QuotationDetails = lazy(() => import('./components/Quotations/QuotationDetails'));
const QuotationsList = lazy(() => import('./components/Quotations/QuotationsList'));
const UpdateQuotation = lazy(() => import('./components/Quotations/UpdateQuotation'));
const ProductSettings = lazy(() => import('./components/settings/ProductSettings'));
const AddTransaction = lazy(() => import('./components/transaction/AddTransaction'));
const DetailTransaction = lazy(() => import('./components/transaction/detailTransaction'));
const Transaction = lazy(() => import('./components/transaction/transaction'));
const CustomerLogin = lazy(() => import('./components/user/CustomerLogin'));
const CustomerLogout = lazy(() => import('./components/user/CustomerLogout'));
const CustomerRegestration = lazy(() => import('./components/user/CustomerRegister'));
const Register = lazy(() => import('./components/user/Register'));
const VatTax = lazy(() => import('./components/vatTax/VatTax'));

const { Sider } = Layout;

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const cartInStorage = JSON.parse(localStorage.getItem("cart"));

    if (!cartInStorage?.length) {
      localStorage.setItem("cart", JSON.stringify([]));
    } else {
      dispatch(loadCartFromStorage(cartInStorage));
    }
  }, [dispatch]);
  return (
    <div className='App container-fluid'>
      <BrowserRouter>
        {/* <TestComp/> */}
        <Main>
          <ToastContainer
            closeButton
            position='bottom-left'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
            theme='colored'
          />
          <Suspense fallback={<div></div>}>
            <Routes>
              <Route path='/' element={<Dashboard />} />

              <Route path='/admin/dashboard' element={<Dashboard />}></Route>
              <Route path='/admin' element={<Dashboard />} />
              <Route path='*' element={<Page404 />} />

              {/* ====================================================
                          Supplier Permetion add here
            ========================================================*/}
              <Route
                element={<UserPrivateRoute permission={"readAll-supplier"} />}
              >
                <Route path='/admin/supplier' exact element={<Suppliers />} />
              </Route>
              <Route
                element={<UserPrivateRoute permission={"readSingle-supplier"} />}
              >
                <Route path='/admin/supplier/:id' element={<DetailsSup />} />
              </Route>
              <Route
                element={<UserPrivateRoute permission={"update-supplier"} />}
              >
                <Route
                  path='/admin/supplier/:id/update'
                  element={<UpdateSup />}
                />
              </Route>

              {/* ====================================================
                          Product Permetion add here
            ========================================================*/}
              <Route
                element={<UserPrivateRoute permission={"readAll-product"} />}
              >
                <Route path='/admin/product' exact element={<Product />} />
              </Route>

              <Route element={<UserPrivateRoute permission={"create-product"} />}>
                <Route path='/admin/add-product' exact element={<AddProd />} />
              </Route>

              <Route
                element={<UserPrivateRoute permission={"readSingle-product"} />}
              >
                <Route path='/admin/product/:id' element={<DetailsProd />} />
              </Route>

              <Route element={<UserPrivateRoute permission={"create-product"} />}>
                <Route
                  path='/admin/product/importcsv'
                  exact
                  element={<ImportFromCSV urlPath={"product"} title='Product' />}
                />
              </Route>

              <Route element={<UserPrivateRoute permission={"update-product"} />}>
                <Route
                  path='/admin/product/:id/update'
                  element={<UpdateProd />}
                />
              </Route>

              {/* <Route element={<UserPrivateRoute permission={"update-sale"} />}> */}
              <Route
                path='/admin/sale/:id/update'
                element={<UpdateSale />}
              />
              {/* </Route> */}

              {/*================================================
                       Product Category Permetion add here
               ================================================*/}

              <Route
                path='/admin/product-category'
                exact
                element={<ProductCategory />}
              />

              <Route
                element={<UserPrivateRoute permission={"readSingle-product"} />}
              >
                <Route
                  path='/admin/product-category/:id'
                  element={<DetailProductCategory />}
                />
              </Route>

              <Route element={<UserPrivateRoute permission={"update-product"} />}>
                <Route
                  path='/admin/product-category/:id/update'
                  element={<UpdateProductCategory />}
                />
              </Route>

              <Route
                element={
                  <UserPrivateRoute permission={"readAll-productSubCategory"} />
                }
              >
                <Route
                  path='/admin/product-subcategory'
                  exact
                  element={<ProductSubcategory />}
                />
              </Route>

              <Route
                element={
                  <UserPrivateRoute
                    permission={"readSingle-productSubCategory"}
                  />
                }
              >
                <Route
                  path='/admin/product-subcategory/:id'
                  element={<DetailProductSubCategory />}
                />
              </Route>

              <Route
                element={
                  <UserPrivateRoute permission={"update-productSubCategory"} />
                }
              >
                <Route
                  path='/admin/product-subcategory/:id/update'
                  element={<UpdateProductSubcategory />}
                />
              </Route>

              <Route
                element={<UserPrivateRoute permission={"readAll-productBrand"} />}
              >
                <Route
                  path='/admin/product-brand'
                  exact
                  element={<ProductBrand />}
                />
              </Route>

              <Route
                element={
                  <UserPrivateRoute permission={"readSingle-productBrand"} />
                }
              >
                <Route
                  path='/admin/product-brand/:id'
                  element={<DetailProductBrand />}
                />
              </Route>

              <Route
                element={<UserPrivateRoute permission={"update-productBrand"} />}
              >
                <Route
                  path='/admin/product-brand/:id/update'
                  element={<UpdateProductBrand />}
                />
              </Route>

              <Route element={<UserPrivateRoute permission={"create-vat"} />}>
                <Route path='/admin/vat-tax' exact element={<VatTax />} />
              </Route>

              <Route path='/admin/product-color' element={<ProductColor />} />

              <Route
                path='/admin/product-color/:id'
                element={<DetailProductColor />}
              />
              <Route
                path='/admin/product-color/:id/update'
                element={<UpdateProductColor />}
              />

              <Route path='/admin/purchase' exact element={<GetAllPurch />} />

              <Route path='/admin/purchase/:id' element={<DetailsPurch />} />
              <Route path='/admin/purchase/:id/update' element={<UpdatePurchase />} />
              <Route
                path='/admin/purchase/return/:id'
                element={<AddReturnPurchase />}
              />

              <Route path='/admin/customer' exact element={<Customer />} />
              <Route path='/admin/customer/:id' element={<DetailCust />} />
              <Route path='/admin/customer/:id/update' element={<UpdateCust />} />
              <Route path='/admin/sale' exact element={<GetAllSale />} />

              <Route path='/admin/sale/add' element={<AddSale />} />
              <Route path='/admin/sale/:id' element={<DetailSale />} />
              <Route path='/admin/sale/return/:id' element={<AddReturnSale />} />
              <Route path='/admin/sale/draft' element={<DraftContainer />} />

              <Route path='/admin/quotation' exact element={<QuotationsList />} />
              <Route path='/admin/quotation/:id' element={<QuotationDetails />} />
              <Route path='/admin/quotation/:id/update' element={<UpdateQuotation />} />
              <Route path='/admin/quotation/return/:id' element={<AddReturnSale />} />

              <Route
                path='/admin/payment/supplier/:pid'
                exact
                element={<AddSupPaymentByInvoice />}
              />
              <Route
                path='/admin/payment/customer/:pid'
                exact
                element={<AddCustPaymentByInvoice />}
              />
              <Route path='/admin/transaction' exact element={<Transaction />} />
              <Route
                path='/admin/transaction/create'
                exact
                element={<AddTransaction />}
              />
              <Route
                path='/admin/transaction/:id'
                element={<DetailTransaction />}
              />

              <Route path='/admin/auth/sso' exact element={<LoginSSO />} />
              <Route path='/admin/auth/super-admin-login' exact element={<SuperAdminLogin />} />
              <Route path='/admin/auth/login' exact element={<Login />} />
              <Route path='/admin/auth/logout' exact element={<Logout />} />
              <Route path='/admin/auth/register' exact element={<Register />} />
              <Route path='/admin/hr/staffs' exact element={<UserList />} />
              <Route
                path='/admin/hr/staffs/:id'
                exact
                element={<DetailStaff />}
              />
              <Route
                path='/admin/hr/staffs/:id/update'
                element={<UpdateStaff />}
              />

              <Route path='/superadmin/vendor' exact element={<VendorList />} />
              <Route
                path='/superadmin/vendor/:id'
                exact
                element={<DetailVendor />}
              />
              <Route
                path='/superadmin/vendor/:id/update'
                element={<UpdateVendor />}
              />

              <Route path='/admin/role' exact element={<RoleList />} />
              <Route path='/admin/role/:id' element={<DetailRole />} />
              <Route path='/admin/role/permit/:id/' element={<AddPermission />} />

              <Route path='/admin/account' exact element={<Account />} />
              <Route path='/admin/account/:id' element={<DetailAccount />} />
              <Route
                path='/admin/account/trial-balance'
                exact
                element={<TrialBalance />}
              />
              <Route
                path='/admin/account/balance-sheet'
                exact
                element={<BalanceSheet />}
              />
              <Route
                path='/admin/account/income'
                exact
                element={<IncomeStatement />}
              />
              <Route path='/admin/designation' exact element={<Designation />} />
              <Route
                path='/admin/designation/:id'
                element={<DetailDesignation />}
              />
              <Route
                path='/admin/designation/:id/update'
                element={<UpdateDesignation />}
              />

              <Route path='/admin/pos' exact element={<Pos />} />

              <Route
                path='/admin/invoice-setting'
                exact
                element={<InvoiceSetting />}
              />
              <Route
                path='/admin/product-setting'
                exact
                element={<ProductSettings />}
              />
              <Route
                path='/admin/customer-setting'
                exact
                element={<CustomerSettings />}
              />
              <Route
                path='/admin/template-setting'
                exact
                element={<TemplateSettings />}
              />

              <Route
                path='/customer/register'
                element={<CustomerRegestration />}
              />
              <Route path='/customer/login' element={<CustomerLogin />} />
              <Route path='/customer/logout' element={<CustomerLogout />} />
            </Routes>
          </Suspense>
        </Main>
      </BrowserRouter>
    </div>
  );
}

export default App;
